import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Home from '@/views/Home.vue'
import Aboutus from '@/views/Aboutus.vue'
import Commoditypage from '@/views/Commoditypage.vue'
import Consultnow from '@/views/Consultnow.vue'
import Hardware from '@/views/Hardware.vue'
import New from '@/views/New.vue'
import Settlement from '@/views/Settlement.vue'
import Case from '@/views/Case.vue'
import Medical from '@/views/Medical.vue'
import HardwareList from '@/views/HardwareList.vue'
import Smarthealthcare from '@/views/Smarthealthcare.vue'
import software from '@/views/software.vue'
const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/aboutus', //关于我们
        name: 'Aboutus',
        component: Aboutus
    },
    {
        path: '/commoditypage', //资讯
        name: 'Commoditypage',
        component: Commoditypage
    },
    {
        path: '/consultnow', //立即咨询
        name: 'Consultnow',
        component: Consultnow
    },
    {
        path: '/HardwareList', //硬件列表
        name: 'HardwareList',
        component: HardwareList
    },
    {
        path: '/hardware', //硬件详情
        name: 'Hardware',
        component: Hardware
    },
    {
        path: '/software', //软件详情
        name: 'software',
        component: software
    },
    {
        path: '/new', //资讯详情
        name: 'New',
        component: New
    },
    {
        path: '/settlement', //申请入驻页
        name: 'Settlement',
        component: Settlement
    },
    {
        path: '/case', //经典案例
        name: 'Case',
        component: Case
    },
    {
        path: '/medical', //医联体
        name: 'Medical',
        component: Medical
    },
    {
        path: '/Smarthealthcare', //经典案例
        name: 'Smarthealthcare',
        component: Smarthealthcare
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {

    if (to.meta.title) {
        document.title = to.meta.title

    } else {
        document.title = ""
    }
    next()
})

export default router